import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var cards = new Swiper('[data-stories]', {
  slidesPerView: 'auto',
  loop: true,
  centeredSlides: false,
  spaceBetween: 50,
  autoplay: false,
  navigation: {
    nextEl: '.button-next',
    prevEl: '.button-prev',
  },
  pagination: false,
});
