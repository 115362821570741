// Vendor imports
import { Fancybox } from '@fancyapps/ui';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import breakpoint from 'alpinejs-breakpoints';
import mask from '@alpinejs/mask';
Alpine.plugin(mask);
Alpine.plugin(intersect);
Alpine.plugin(breakpoint);
Alpine.plugin(collapse);
Alpine.plugin(focus);
window.Alpine = Alpine;
window.AlpineBreakpointPluginBreakpointsList = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
  '3xl',
];
window.Alpine.start();

import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';

// Module imports
import './modules/svgIconSprite';
import './modules/carousel';
